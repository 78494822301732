<template>
  <div>
    <h1 class="text-18 text-gray-700 font-bold mb-5 mt-10">Repayments</h1>
    <div class="pt-6 md:pt-0 lg:pt-10 pb-12 mb-16 flex flex-col">

      <sm-loader
        v-if="isLoading"
        class="mb-32"
      />

      <div class="mb-3 -mt-5" v-else-if="activeLoan && nextRepayment">
        <div class="text-24 font-bold mr-16 text-credpal-400">
          ₦ {{ nextRepayment.amount | currency }}
        </div>
        <div class="text-red-500 opacity-50 text-sm" v-if="nextRepaymentDefaultCharge && nextRepaymentDefaultCharge > 0">
          + ₦ {{ nextRepaymentDefaultCharge | currency }} <span class="text-xs">default charge</span>
        </div>
        <div
          class="text-green-500 opacity-50 text-sm mb-5"
          v-if="fractionalPayment && fractionalPayment > 0"
        >
          - ₦ {{ fractionalPayment | currency }}
          <span class="text-xs">Fraction Paid</span>
        </div>
        <div
          class="text-green-500 opacity-50 text-sm"
          v-if="totalProcessing && totalProcessing > 0"
        >
          - ₦ {{ totalProcessing | currency }}
          <span class="text-xs">Awaiting confirmation</span>
        </div>
        <div class="text-12 text-ash-700 mb-6">
          Next Repayment
        </div>
        <div class="flex flex-row items-center">
          <button
            type="button"
            class="btn btn-blue px-12 btn-md text-center"
            @click="pay(nextRepayment)"
          >
            <span>Repay Now</span>
          </button>
          <!-- <button type="button" class="btn btn-md text-credpal-400 bg-credpal-300 text-center ml-3">
                  <span>Clear all repayments</span>
                </button> -->
        </div>
        <div class="flex flex-row mt-5">
          <div class="alert-icon bg-green-200 text-green-500 mr-3">!</div>
          <div class="text-10 text-credpal-600 w-full mt-1 lg:w-1/2">
            Your next payment is due on
            {{ nextRepayment.due_date | dateFormat("M dd, Y") }}
          </div>
        </div>
      </div>
      <div class="mb-3 -mt-5" v-else>
        <svg
          width="48"
          height="48"
          viewBox="0 0 48 48"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <rect opacity="0.06" width="48" height="48" rx="8" fill="#515B8D" />
          <path
            d="M18.2387 27.6389L18.2315 32.4978M18.2315 32.4978H14M18.2315 32.4978L16.9289 31.0711C15.293 29.4351 14.2749 27.2826 14.0482 24.9802C13.8214 22.6778 14.4 20.3679 15.6853 18.4443C16.9707 16.5206 18.8832 15.1022 21.0972 14.4306C23.3111 13.759 25.6894 13.8758 27.8268 14.7612C29.9643 15.6466 31.7286 17.2457 32.8192 19.286C33.9098 21.3264 34.2592 23.6818 33.8078 25.9509C33.3565 28.22 32.1323 30.2624 30.3439 31.7301C28.5555 33.1978 26.3135 34 24 34M27.9428 23.6967H23.6975L23.6967 18.8447"
            stroke="#515B8D"
            stroke-width="1.5"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>
        <div class="pt-6 pb-6 opacity-50">
          <p class="text-12 text-credpal-700">
            You currently have no active standard loan.<br />
            All your loans repayments will be shown here.
          </p>
        </div>
        <div class="flex flex-row items-center">
          <button
            type="button"
            class="btn btn-blue btn-md text-center"
            @click="requestLoan"
          >
            <span>Request Loan</span>
          </button>
        </div>
      </div>
      <div
        class="mt-12 lg:w-5/12 md:w-5/12"
        v-if="activeLoan && nextRepayment"
      >
        <h3 class="text-18 text-credpal-400 font-bold mb-6">
          Upcoming Repayments
        </h3>
        <datatable
          :data="repayments.data"
          :columns="repaymentColumns"
          :limit="10"
          :loading="repayments.loading"
          :showHeadings="false"
          :fillable="false"
          table-style="flush"
          v-if="repayments.data.length || repayments.loading"
        />
      </div>
    </div>
    <payment-repayment
      :repayment="selectedRepayments"
      :payment-reference="paymentReference"
      @success="repaymentSuccess"
      ref="payment"
    />
  </div>
</template>
<script>
export default {
  data() {
    return {
      repayment: null,
      selectedRepayments: null,
      repaymentColumns: [
        {
          name: "description",
          th: "Description",
          render: (repayment, b, index) => {
            if (index + 1 == 1) {
              return ` <div class="text-gray-700 font-medium text-sm">${index +
                1}st Repayment</div>
                  <div class="text-gray-500 text-xs">${this.$options.filters.dateFormat(
                    repayment.due_date,
                    "M dd"
                  )}, ${this.$options.filters.dateFormat(
                repayment.due_date,
                "Y"
              )}</div>
                `;
            } else if (index + 1 == 2) {
              return ` <div class="text-gray-700 font-medium text-sm">${index +
                1}nd Repayment</div>
                      <div class="text-gray-500 text-xs">${this.$options.filters.dateFormat(
                        repayment.due_date,
                        "M dd"
                      )}, ${this.$options.filters.dateFormat(
                repayment.due_date,
                "Y"
              )} </div>
                    `;
            } else if (index + 1 == 3) {
              return ` <div class="text-gray-700 font-medium text-sm">${index +
                1}rd Repayment</div>
                      <div class="text-gray-500 text-xs">${this.$options.filters.dateFormat(
                        repayment.due_date,
                        "M dd"
                      )}, ${this.$options.filters.dateFormat(
                repayment.due_date,
                "Y"
              )} </div>
                    `;
            } else if (index + 1 > 3) {
              return ` <div class="text-gray-700 font-medium text-sm">${index +
                1}th Repayment</div>
                      <div class="text-gray-500 text-xs">${this.$options.filters.dateFormat(
                        repayment.due_date,
                        "M dd"
                      )}, ${this.$options.filters.dateFormat(
                repayment.due_date,
                "Y"
              )} </div>
                    `;
            }
          },
        },
        {
          align: "right",
          name: "status",
          th: "Status",
          render: (repayment) => {
            return `
                <div class="text-gray-700 font-medium text-sm">₦ ${this.$options.filters.currency(
                  repayment.amount
                )}</div>
                <div class="text-gray-500 text-xs flex items-center justify-end">
                  <span class="w-5px h-5px rounded-full bg-credpal-800 inline-block mr-1 -mt-1"></span>
                  <span>Upcoming</span>
                </div>
              `;
          },
        },
      ],
      form: this.$options.basicForm([
        { name: "percentage", value: "100" },
        { name: "payment_method", value: "paystack" },
      ]),
    };
  },
  computed: {
    activeLoan() {
      return this.loans.data?.find((loan) =>
        loan.status?.match(/pending|approved|accepted|disbursed|confirmed/i)
      );
    },
    isLoading() {
      return this.repayments.loading || this.loans.loading;
    },
    loans() {
      return this.resources.loans;
    },
    nextRepayment() {
      return this.repayments.data?.[0];
    },
    nextRepaymentDefaultCharge() {
      return this.nextRepayment?.default_charge?.amount || 0;
    },
    paymentReference() {
      const date = new Date().format("dd/mm/Y");
      const random = Math.round(Math.random() * 100000);

      return `${this.user?.name}—${date}-${random}`;
    },
    repayments() {
      return this.resources.repayments;
    },
    fractionalPayment() {
      return this.nextRepayment?.repayment_wallet?.total_paid || 0;
    },
    totalProcessing() {
      return this.nextRepayment?.totalProcessingAmount || 0;
    }
  },
  beforeMount() {
    this.getLoans();
    this.getRepayments();
  },
  methods: {
    requestLoan() {
      if (!this.validateUser('onboarding')) {
        return this.resumeOnboarding();
      }

      this.$router.push({ name: 'dashboard', query: { loanRequest: true } });
    },
    getLoans(forced = false) {
      this.loadResource('loans', forced);
    },
    getRepayments(forced = false) {
      this.loadResource('repayments', forced);
    },
    repaymentSuccess() {
      this.getRepayments();
      // this.getLoanSummary();
    },
    pay(repayment) {
      this.repayment = repayment;
      this.selectedRepayments = repayment;
      this.$refs.payment.open();
    },
  },
};
</script>
