<template>
	<div>

		<h1 class="text-2xl font-bold mb-5">All Repayments</h1>

		<div class="border border-solid border-blue-200 rounded overflow-hidden">
			<datatable :data="repayments" :columns="columns" :loading="loading" :query="searchQuery" ref="table" />
		</div>

	</div>
</template>
<script>
	export default {
		data() {
			return {
				searchQuery: '',
				company: null,
				loading: false,
				repayments: [],
				columns: [
					{
						name: 'user',
						th: 'Staff',
						render: (repayment, user) => user?.name
					},
					{
						name: 'amount',
						th: 'Amount',
						render: (repayment, amount) => `₦ ${this.$options.filters.currency(amount)}`
					},
					{
						name: 'status',
						th: 'Status',
						render: repayment => {
							switch (repayment.status) {
								case 'paid':
									return `<div class="rounded-sm border border-green-500 text-green-500 bg-green-100 text-xs px-3 py-2 inline-block">
										Paid
									</div>`;
								case 'transferred':
									return `<div class="rounded-sm border border-green-500 text-green-500 bg-green-100 text-xs px-3 py-2 inline-block">
										Transferred
									</div>`;
								case 'not_due':
									return `<div class="rounded-sm border border-blue-300 text-blue-300 bg-blue-100 text-xs px-3 py-2 inline-block">
										Not Due
									</div>`;
								case 'due':
									return `<div class="rounded-sm border border-blue-300 text-blue-300 bg-blue-100 text-xs px-3 py-2 inline-block">
										Due
									</div>`;
								default:
									if (repayment.defaulted) {
										return `<div class="rounded-sm border border-red-500 text-red-500 bg-red-100 text-xs px-3 py-2 inline-block">
											Default
										</div>`;
									}
									break;
							}
						}
					},
					{
						name: 'due_date',
						th: 'Due Date',
					},
				],
			}
		},
		beforeMount() {
			this.getRepayments();
		},
		mounted() {
			
		},
		methods: {
			async getRepayments() {
				this.loading = true;
				await this.$get({
					url: `${this.$baseurl}/companies/repayments/${this.user.company_id}`,
					headers: this.headers,
					success: (response) => {
						this.repayments = response.data.repayments;
					}
				});
				this.loading = false;
			},
		}
	}
</script>